import {
  ActionFunctionArgs,
  json,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";
import {
  Form,
  Link,
  useActionData,
  useFetcher,
  useNavigation,
} from "@remix-run/react";
import { useState } from "react";
import DiscordSvg from "~/assets/images/discord-mark-blue.svg";
import { Loader } from "~/components/loader";
import { createServerSupabase } from "~/libs/supabase";

export const meta: MetaFunction = (data) => {
  if (data?.data?.redirectParams) {
    return [
      { title: "Login - Osia Novel" },
      { name: "robots", content: "noindex" },
    ];
  }
  return [{ title: "Login - Osia Novel" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const [, supabase] = createServerSupabase(request);
  const url = new URL(request.url);
  const params = new URLSearchParams(url.search);

  //get user
  const redirectParams = params.get("redirect");

  // if (user) return redirect("/" + redirectParams, { headers });
  return json({ redirectParams });
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const [headers, supabase] = createServerSupabase(request);
  const url = new URL(request.url);
  const params = new URLSearchParams(url.search);
  const redirectParams = params.get("redirect");
  const formData = await request.formData();
  const action = formData.get("action") as string;
  const captchaToken = formData.get("captcha_token");

  switch (action) {
    case "login": {
      const honeypot = formData.get("contact_me");
      const credentials = {
        email: formData.get("email") as string,
        password: formData.get("password") as string,
        // captchaToken,
      };

      if (honeypot) {
        const { error } = await supabase.from("logs").insert({
          data: "spam : " + JSON.stringify(credentials),
          type: "register_honeypot",
        });

        if (error) console.log(error);
        return redirect("/");
      }
      const { error } = await supabase.auth.signInWithPassword(credentials);

      console.log("error", error);

      if (error) {
        const message =
          error.message == "Invalid login credentials"
            ? "Invalid login credentials"
            : "An error occurred";

        console.log("error", error);
        return json({ error: message ?? "An error occurred" });
      }

      // revalidate('/', 'layout')
      if (!redirectParams || redirectParams === "null")
        return redirect("/", { headers });

      return redirect("/" + redirectParams, { headers });
    }
    case "login_discord": {
      const redirectUrl =
        process.env.NODE_ENV === "production"
          ? "https://www.osianovel.com/"
          : "http://localhost:5173/";
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "discord",
        options: {
          redirectTo: redirectUrl + "auth/callback",
        },
      });

      if (data.url) {
        return redirect(data.url, { headers }); // use the redirect API for your server framework
      }

      if (error) {
        console.log("error", error);
        return json({ error: "An error occurred" });
      }
      return json({ data }, { headers });
    }
    default:
      return json({ error: "Invalid action" });
  }
};

export default function Login() {
  const navigation = useNavigation();
  const [captchaToken, setCaptchaToken] = useState();
  const actionData = useActionData();
  const fetcher = useFetcher();
  return (
    <div className="h-[75vh] flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 space-y-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form className="space-y-6" method="POST">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <input type="hidden" name="action" value="login" />
        
          <input type="hidden" name="captcha_token" value={captchaToken} />
          <input
            type="checkbox"
            name="contact_me"
            value="1"
            style={{ display: "none" }}
            tabIndex="-1"
            autoComplete="off"
          />
          {actionData?.error && (
            <div className="text-red-500 text-sm text-center">
              {actionData.error}
            </div>
          )}
          <div>
            <button
              disabled={navigation.state !== "idle"}
              type="submit"
              className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              {navigation.state === "submitting" ? <Loader /> : "Login"}
            </button>
          </div>

          <div>
            <Link
              to={"/register"}
              className="flex w-full justify-center rounded-md border border-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-green-600 shadow-sm hover:text-white hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              {"Register"}
            </Link>
          </div>
          {/* or line */}
        </Form>
        <div className="flex items-center justify-center">
          <div className="h-0.5 bg-gray-200 rounded w-full" />
          <div className="text-sm px-4 shrink-0 leading-5 text-gray-600">
            Or continue with
          </div>
          <div className="h-0.5 bg-gray-200 rounded w-full" />
        </div>
        <button
          type="button"
          onClick={() => {
            fetcher.submit(
              {
                action: "login_discord",
              },
              {
                method: "POST",
              }
            );
          }}
          className="w-full flex items-center justify-center bg-white dark:bg-gray-900 border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 dark:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          <img src={DiscordSvg} alt="" className="h-6 w-6" />
          <span className="pl-3">Continue with Discord</span>
        </button>
      </div>
    </div>
  );
}
